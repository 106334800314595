.AppContainer {
  display: flex; 
  flex:1; 
  flex-direction: column;
  align-items: center;  
  width: 100%;  
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

.AppHeader{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #006EFF;
  padding-top: 10px;
  width: 100%;
  height: 65px;
  color: white;
  z-index: 2;
 
}

.NavigationWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 100%;
}

.LogoWrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
}
.HeaderLogo{
  width: 115px;
  height: auto;
  cursor: pointer;
}

.Navigation{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 100%;
}
.Navigation p{
  cursor: pointer;
}

.MainContentBackground{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  z-index: 1;
}


.MainContent{
  display: flex;
  width: 100%;
   
 
  justify-content: center;
  z-index: 2;
}

.MainContentBackground::before{
  content: "";
  background-image: linear-gradient(180deg, #006EFF 0%, #fff 96.18%);
 
  position: absolute;
  opacity: 0.7;
  width: 100%;
  height: 100vh;  

}
.LoginWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;  
  z-index: 2;
  @media screen and (max-width: 1080px) {
    width: 85%;
  }
}

.LoginHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;  
  width: 100%;
}

.LoginHeader h1{
  font-size: 36px;
  font-weight: 800;
  line-height: 140%;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  @media screen and (max-width: 500px) {
    font-size: 26px;
  }
}

.LoginBody{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.LoginBodyContentItem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.LoginBodyContentItem input{
  width: 93%;
  height: 46px;
  color: #8c8c8c;
  border: 1px solid #8c8c8c;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
}
.LoginBodyContentCaptchaRow{
  display: flex;
  flex-direction: row;
  
  width: 99%;
  margin-bottom: 20px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}
.LoginBodyContentCaptchaImageRow{
  display: flex;
  align-items: center;
}

.LoginBodyContentCaptchaTextRow{
  display: flex;
  align-items: center;
  width: 53%;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-top: 20px;
  }
}

.LoginBodyContentCaptchaRow input{
  
  height: 46px;
  color: #8c8c8c;
  border: 1px solid #8c8c8c;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  margin-left: 25px;
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
}


.captchaRefresh{
  width: 32px;
  height: 32px;
  margin-left: 25px;
}

.LoginBodyContentItem button{ 
  height: 46px;
  width: 100%;
  border-radius: 2px;
  border: 2px solid #FFB300;
  background-color: #FFB300;
  color: black;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.SupportWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;  
  z-index: 2;
  @media screen and (max-width: 920px) {
    width: 80%;
  }
}

.SupportHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.SupportHeader h1{
  font-size: 36px;
  font-weight: 800;
  line-height: 140%;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  @media screen and (max-width: 500px) {
    font-size: 26px;
  }
}

.SupportBody{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.MainContentKunden{
  display: flex;
  width: 100%;
  
  justify-content: center;
  z-index: 2;
}

.MainContentBackgroundKd{
  display: flex;
 
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  z-index: 1;
}



.MainContentBackgroundKd::before{
  content: "";
  background-image: linear-gradient(180deg, #006EFF 0%, #fff 96.18%); 
  position: absolute;
  opacity: 0.7;
  width: 100%;
  height: 100vh;  

}


.KundeWraper{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  height: 100%;  
  z-index: 2;
  margin-left: 20px;
  @media screen and (max-width: 920px) {
    width: 100%;
  }
}

.KundeHeader{
  display: flex;
  flex-direction: column;
  
  width: 100%;
}

.KundenHeader h1{
  font-size: 36px;
  font-weight: 800;
  line-height: 140%;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  @media screen and (max-width: 500px) {
    font-size: 26px;
  }
}

.KundenBody{  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;; 
  z-index: 2;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin-bottom: 15px;
  @media screen and (max-width: 920px) {
    width: 84%;
  }
  
}

.EventName{
  display: flex; 
  width: 100%;  
  flex-direction: column;

}
.EventData{
  display: flex; 
  width: 100%; 
  flex-direction: column;
}
.EventData table{
  width: 70%;
}
.EventData td{
  padding: 5px;
  vertical-align: text-top;
}

.TicketTableWrapper{
  margin-bottom: 20px;

}

.FooterContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 60px;
  font-size: 10px;
  z-index: 2;

}

.FooterContentWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 100%;
}

.FooterContentItem{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.FooterContentItem h3{
  font-size: 15px;
  cursor: pointer;
}

.RechtlWrapper{
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  
  align-items: center;
  width: 60%;
  height: 80vh; 
  
  overflow: auto;; 
  z-index: 2;
  padding: 20px;
  
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  @media screen and (max-width: 920px) {
    width: 80%;
  }

  
}
